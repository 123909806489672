<template>
  <v-app-bar id="app-toolbar" app light dense>
    <v-container class="pa-0 d-flex">
      <common-app-logo :useDark="true" class="mr-12" />
      <v-toolbar-items>
        <v-btn
          text
          class="text-capitalize font-weight-medium hover"
          active-class="hover"
          :to="{ name: 'dashboard' }"
          >Event App
        </v-btn>
        <v-btn
          text
          class="text-capitalize font-weight-medium hover"
          active-class="hover"
          :to="{ name: 'calendar' }"
          >Calendar</v-btn
        >
        <v-btn
          text
          class="text-capitalize font-weight-medium hover"
          active-class="hover"
          :to="{ name: 'tasks' }"
          >Tasks</v-btn
        >
        <v-btn
          text
          class="text-capitalize font-weight-medium hover"
          active-class="hover"
          :to="{ name: 'notifications' }"
          >Notifications</v-btn
        >
      </v-toolbar-items>
      <v-spacer />

      <v-toolbar-items>
        <core-avatar v-if="isLoggedin"></core-avatar>
      </v-toolbar-items>
    </v-container>
  </v-app-bar>
</template>

<script>
import { get } from 'vuex-pathify';
export default {
  name: 'BlankAppBar',
  computed: {
    isLoggedin: get('auth/isLoggedin')
  }
};
</script>
<style lang="scss">
//styles/variables.scss

.hover:hover {
  // color: $primary;
  border-bottom-style: solid;
  border-width: 2px;
  // border-color:  ;
}
</style>
